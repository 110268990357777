import gsap, { Power3 } from 'imports/gsap'
import siteCommon from 'site/site-common'
import score from 'site/score'
let soundtrack = {
    total_tracks:0,
    current_track:-1,
    mp3:null,
    seektime:0,
    is_playing:false,
    doAutoPlay:false,
    init:function () {
        var self = this;
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li .icon').on('click',self.listClick);
        soundtrack.total_tracks=$('#soundtrack.film-block .soundtrack-module .soundtrack-list li').length;
        soundtrack.current_track=-1;
        self.reset();
    },//init

    reset:function() {
        //set all icons to paused
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').addClass('state_paused');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave').hide();
        $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave.off').show();
    },

    stopSound:function(noLoop=false) {
        if (typeof(soundtrack.mp3) !== 'undefined' && soundtrack.mp3 !== null) {
            soundtrack.mp3.pause();
            soundtrack.is_playing=false;
            $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave').hide();
            $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave.off').show();
            soundtrack.setPauseIcon();
        }
        if (!noLoop) {
            score.stopSound(true)
        }

    },
    resumeSound:function() {
        if (typeof(soundtrack.mp3) !== 'undefined' && soundtrack.mp3 !== null) {
            soundtrack.mp3.play();
            soundtrack.is_playing = true;
            $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave').hide();
            $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave.on').show();
            soundtrack.setPlayIcon();
        }
    },
    playSound:function(mp3file) {
        if (typeof(soundtrack.mp3) !== 'undefined' && soundtrack.mp3 !== null) {
            soundtrack.mp3.removeEventListener("ended", soundtrack.soundEnded);
            soundtrack.stopSound();
            soundtrack.mp3.currentTime = 0;
        }
        soundtrack.mp3 = new Audio(mp3file);
        soundtrack.mp3.addEventListener("ended", soundtrack.soundEnded);
        soundtrack.mp3.addEventListener("timeupdate", soundtrack.soundUpdate);
        soundtrack.mp3.play();
        soundtrack.is_playing=true;

        $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave').hide();
        $('#soundtrack.film-block .soundtrack-module .soundtrack-player .wave.on').show();

        //icon
        soundtrack.setPlayIcon()

        //player
        soundtrack.initNewPlayer()
    },

    setPlayIcon:function() {
        //reset others
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').removeClass('state_playing');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').addClass('state_paused');

        //set icon
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).addClass('state_playing');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).removeClass('state_paused');
    },
    setPauseIcon:function() {
        //reset others
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').removeClass('state_playing');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').addClass('state_paused');

        //set icon
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).removeClass('state_playing');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).addClass('state_paused');
    },

    soundUpdate:function() {
        if (soundtrack.mp3) {
            var disp_ct,disp_duration,pct;
            var ogpct = soundtrack.mp3.currentTime/soundtrack.mp3.duration;
            if (soundtrack.mp3.currentTime>0) {
                disp_ct = siteCommon.toHHMMSS(soundtrack.mp3.currentTime);
                disp_duration = siteCommon.toHHMMSS(soundtrack.mp3.duration);
                pct = 100*ogpct;
            } else {
                disp_ct="";
                disp_duration="";
                pct = 0;
            }
            $('#soundtrack.film-block #soundtrack-time-current span').html(disp_ct);
            $('#soundtrack.film-block #soundtrack-time-duration span').html(disp_duration);
            $('#soundtrack.film-block .scrubbar-pct').css('width',pct+"%");
            //$('#scrubbar-dot').css('left',pct+"%");

            var maxw = $('#soundtrack.film-block .scrubbar-dot-container').width();
            var thex = ogpct * maxw - 9;
            $('#soundtrack.film-block li.active .scrubbar-dot-inner').css('left',thex+"px");
        }

    },

    jumpToPct:function(pct) {
        soundtrack.seektime = parseFloat(soundtrack.mp3.duration * pct);
        soundtrack.mp3.currentTime = soundtrack.seektime;
        soundtrack.mp3.addEventListener("playing", soundtrack.soundPlaying);
        soundtrack.resumeSound();
    },

    soundPlaying:function() {
        soundtrack.mp3.removeEventListener("playing", soundtrack.soundPlaying);
        soundtrack.mp3.currentTime = soundtrack.seektime;
    },

    soundEnded:function() {
        soundtrack.current_track++;
        if (soundtrack.current_track>=soundtrack.total_tracks) {
            soundtrack.current_track=0;
        }
        soundtrack.setSongFromIndex();
    },
    listClick:function() {
        score.stopSound(true)

        const parent = $(this).closest('li')
        //const newindex = $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').index(this);
        const newindex = $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').index(parent);
        if (newindex === soundtrack.current_track) {
            if (soundtrack.is_playing) {
                //stop
                soundtrack.stopSound();
            } else {
                //resume
                soundtrack.resumeSound();
            }
        } else {
            //new track
            var thex = -9;
            $('#soundtrack.film-block .scrubbar-dot-inner').removeClass('notdragged')
            $('#soundtrack.film-block .scrubbar-dot-inner').css('left',thex+"px");
            $('#soundtrack.film-block .scrubbar-dot-inner').addClass('notdragged')
            soundtrack.current_track = newindex;
            soundtrack.setSongFromIndex();
        }





    },
    setSongFromIndex:function() {
        soundtrack.soundUpdate()
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').removeClass('active');
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).addClass('active');
        var mp3file = $('#soundtrack.film-block .soundtrack-module .soundtrack-list li').eq( soundtrack.current_track ).attr('mp3');
        soundtrack.playSound(mp3file);
        soundtrack.setPlayIcon()

        //$( "#soundtrack.film-block li" ).draggable({
        $( "#soundtrack.film-block .drag-hook" ).draggable({
            axis: "x",
            containment: "parent",
            cursor: 'move',
            drag: function(event,ui){
                if (typeof(soundtrack.mp3) !== 'undefined' && soundtrack.mp3 !== null) {
                    $('#soundtrack.film-block .drag-hook').removeClass('notdragged')
                    soundtrack.stopSound()
                    return true
                } else {
                    return false
                }
            },
            stop: function( event, ui ) {
                if (typeof(soundtrack.mp3) !== 'undefined' && soundtrack.mp3 !== null) {
                    var maxw = $('#soundtrack.film-block .scrubbar-dot-container').width()
                    var thex = siteCommon.pxToNum($('#soundtrack.film-block .scrubbar-dot-inner').css('left'))
                    var pct = thex / maxw
                    $('#soundtrack.film-block .drag-hook').addClass('notdragged')
                    soundtrack.jumpToPct(pct)
                }
            }
        })
    },

    startAutomatically:function() {
        if (filmObject.section !== 'song' && filmObject.section !== 'score') {
            soundtrack.doAutoPlay = true
        }
        if (soundtrack.doAutoPlay) {
            soundtrack.current_track = 0
            soundtrack.setSongFromIndex()
        }
        soundtrack.doAutoPlay = true
    },

    initNewPlayer:function() {
        //clear old
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li .hook-player').html('')

        //add new player
        let thtml = $('#soundtrack.film-block .film-soundtrack-meta').html()
        $('#soundtrack.film-block .soundtrack-module .soundtrack-list li.active .hook-player').html(thtml)

        //reveal
        gsap.fromTo('#soundtrack.film-block .soundtrack-player', 2,
            {
                opacity: 0
            },
            {
                opacity: 1,
                ease: Power3.easeOut
            }
        );

    }
}
export default soundtrack
